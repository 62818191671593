import { useState } from "react";

import { fromBase64, toHex } from "@cosmjs/encoding";

import { Icon } from "../Icon";

type Props = {
  value: string;
};
export const HexDecodeText = ({ value }: Props) => {
  const [isConverted, setIsConverted] = useState(false);

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex-1 truncate">
        {!isConverted ? value : toHex(fromBase64(value)).toUpperCase()}{" "}
      </div>

      <Icon
        className="size-5 cursor-pointer text-corduroy-500 transition-colors hover:text-tusk-300"
        name="rotate"
        onClick={() => setIsConverted((prev) => !prev)}
      />
    </div>
  );
};
