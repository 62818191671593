import { useCallback, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { Bar, BarChart as RBarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import type { LatestBlock } from "shared/api/blocks/types";

import { theme } from "app/theme";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";

type Props = {
  latestBlocks: LatestBlock[];
};

type DataChart = {
  block: null | string;
  dummyFullBar: number;
  time: null | string;
  transactionsCount: null | number;
} | null;

export const BarChart = ({ latestBlocks }: Props) => {
  const [yAxisWidth, setYAxisWidth] = useState(26);

  const maxTxCount = useMemo(() => {
    if (!latestBlocks.length) return 1;
    return Math.max(...latestBlocks.map((block) => block.transactions.length));
  }, [latestBlocks]);

  const data = useMemo(() => {
    const blocks: DataChart[] = [];
    for (let i = 99; i >= 0; i--) {
      if (latestBlocks[i]) {
        blocks.push({
          block: latestBlocks[i].latestBlock,
          dummyFullBar: 0,
          time: latestBlocks[i].time,
          transactionsCount: latestBlocks[i].transactions.length,
        });
      }
    }

    if (blocks.length < 100) {
      blocks.push(
        ...new Array(100 - blocks.length).fill({
          block: null,
          dummyFullBar: maxTxCount,
          time: null,
          transactionsCount: null,
        }),
      );
    }
    return blocks;
  }, [latestBlocks, maxTxCount]);

  const tickFormatter = useCallback((transactionsCount: number) => {
    const label = `${transactionsCount}`;
    const width = label.length * 13;
    setTimeout(() => setYAxisWidth((prev) => (prev > width ? prev : width)));

    return label;
  }, []);

  return (
    <div className="mb-5 h-44 rounded-lg bg-clay-900 p-4 pb-0 shadow-md">
      <ResponsiveContainer>
        <RBarChart
          barCategoryGap="0%"
          barGap={-4}
          data={data}
          margin={{ bottom: -10, left: 0, right: 0, top: 0 }}
        >
          <YAxis
            axisLine={false}
            dataKey="transactionsCount"
            domain={[0, "dataMax"]}
            minTickGap={0}
            padding={{ bottom: 0, top: 0 }}
            tick={{ fill: theme.colors.clay[500], fontFamily: "Inter", fontSize: 12 }}
            tickCount={5}
            tickFormatter={tickFormatter}
            tickLine={false}
            width={yAxisWidth}
          />
          <XAxis axisLine={false} tick={false} tickLine={false} />

          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              fill: theme.colors.clay[100],
              opacity: 0.4,
              stroke: "transparent",
              strokeWidth: 1,
            }}
            wrapperStyle={{ outline: "none" }}
          />

          <Bar barSize={4} dataKey="dummyFullBar" fill={theme.colors.clay[850]} />
          <Bar barSize={4} dataKey="transactionsCount" fill={theme.colors.purple[800]} />
        </RBarChart>
      </ResponsiveContainer>
    </div>
  );
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: [{ payload: DataChart }];
};

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  const data = payload?.[0]?.payload;
  if (!data || !active || !data.block || !data.time) return null;

  return (
    <div className="grid grid-cols-1 gap-2 rounded-md bg-clay-900 p-1.5 shadow-md">
      <div className="flex items-center justify-between gap-3 text-clay-300">
        <span className="text-xs font-medium">Block:</span>
        <span className="text-xs font-semibold">{getFormattedNumber(data.block || undefined)}</span>
      </div>

      <div className="flex items-center justify-between gap-3 text-clay-300">
        <span className="text-xs font-medium">Txn:</span>
        <span className="text-xs font-semibold">{data.transactionsCount}</span>
      </div>

      <div className="flex items-center justify-between gap-3 text-clay-300">
        <span className="text-xs font-medium">Time:</span>
        <span className="text-xs font-semibold">{timeAgo(DateTime.fromISO(data.time))}</span>
      </div>
    </div>
  );
};
