import type { ComponentProps, PropsWithChildren } from "react";
import { createContext, forwardRef, useContext } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

import type { ClassName } from "shared/types";

import { Icon } from "../Icon";

const DialogOpenContext = createContext<boolean>(false);

const contentVariants: ComponentProps<typeof motion.div>["variants"] = {
  animate: { opacity: 1, scale: 1, translateY: 0 },
  exit: { opacity: 0, scale: 0.8, translateY: 100 },
  initial: { opacity: 0, scale: 0.8, translateY: -100 },
};
const overlayVariants: ComponentProps<typeof motion.div>["variants"] = {
  animate: { opacity: 1 },
  closed: { opacity: 0 },
};
const transition: ComponentProps<typeof motion.div>["transition"] = {
  duration: 0.2,
};

export const Modal = ({ children, ...props }: Dialog.DialogProps) => {
  return (
    <DialogOpenContext.Provider value={props.open || false}>
      <Dialog.Root {...props}>{children}</Dialog.Root>
    </DialogOpenContext.Provider>
  );
};

const ModalContent = forwardRef<
  HTMLDivElement,
  { dialogClassName?: string; wrapperClassName?: string } & Dialog.DialogContentProps
>(({ children, className, dialogClassName, wrapperClassName, ...props }, ref) => {
  const isOpen = useContext(DialogOpenContext);

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog.Portal forceMount>
          <div
            className={twMerge(
              "pointer-events-auto fixed inset-x-0 top-0 z-40 flex h-full flex-col p-3",
              wrapperClassName,
            )}
          >
            <Dialog.Overlay asChild>
              <motion.div
                animate="animate"
                className="absolute inset-0 bg-corduroy-900/50"
                exit="closed"
                initial="closed"
                transition={transition}
                variants={overlayVariants}
              />
            </Dialog.Overlay>
            <motion.div
              animate="animate"
              className={twMerge(
                "relative z-10 m-auto flex max-h-full w-full max-w-none md:max-w-lg",
                className,
              )}
              exit="exit"
              initial="initial"
              transition={transition}
              variants={contentVariants}
            >
              <Dialog.Content
                {...props}
                className={twMerge(
                  "w-full overflow-auto rounded-xl bg-clay-900 p-6 focus:outline-none",
                  dialogClassName,
                )}
                ref={ref}
              >
                {children}
              </Dialog.Content>
            </motion.div>
          </div>
        </Dialog.Portal>
      )}
    </AnimatePresence>
  );
});

const ModalCloseButton = ({ className }: ClassName) => {
  return (
    <Dialog.Close className={twMerge("absolute right-2 top-2 outline-none", className)}>
      <Icon className="text-corduroy-500 transition-colors hover:text-corduroy-900" name="close" />
    </Dialog.Close>
  );
};

const ModalTitle = ({ children, className }: PropsWithChildren<ClassName>) => {
  return (
    <Dialog.DialogTitle className={twMerge("mb-3 text-3xl font-light text-white", className)}>
      {children}
    </Dialog.DialogTitle>
  );
};

const ModalScrollableContent = ({ children, className }: PropsWithChildren<ClassName>) => {
  return <div className={twMerge("overflow-scroll ", className)}>{children}</div>;
};

Modal.Content = ModalContent;
Modal.CloseButton = ModalCloseButton;
Modal.Title = ModalTitle;
Modal.ScrollableContent = ModalScrollableContent;
