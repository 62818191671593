import { type ComponentPropsWithoutRef } from "react";

import { twMerge } from "tailwind-merge";

import { Spinner } from "shared/ui/Spinner";

export const TableTdTitle = (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={twMerge("w-56 text-base text-white md:font-normal md:text-white", props.className)}
    />
  );
};

export const TableTr = (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div
      {...props}
      className={twMerge(
        "flex flex-col gap-3 border-t border-clay-900 py-3.5 first-of-type:border-t-0 md:flex-row",
        props.className,
      )}
    />
  );
};

export const TableTdValue = (
  props: {
    isLoading?: boolean;
  } & ComponentPropsWithoutRef<"td">,
) => {
  const { children, isLoading, ...otherProps } = props;

  return (
    <div
      {...otherProps}
      className={twMerge(
        "flex flex-1 items-center text-sm font-light text-clay-300",
        props.className,
      )}
    >
      {isLoading ? <Spinner className=" size-4" /> : children}
    </div>
  );
};

export const TableWrapper = (props: ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={twMerge("overflow-x-auto", props.className)}>
      <div className="w-min min-w-full">{props.children}</div>
    </div>
  );
};
