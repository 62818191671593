import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useProposalsQuery } from "shared/api/proposals";
import { useStakingPoolQuery } from "shared/api/staking/useStakingPoolQuery";
import { getChainById } from "shared/helpers/getChainById";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Button } from "shared/ui/Button";
import { Card } from "shared/ui/Card";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Tabs } from "shared/ui/Tabs";

import { ProposalItem } from "./ui/ProposalItem";

export const Governance = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState("PROPOSAL_STATUS_VOTING_PERIOD");

  const { chainId } = useParams<{ chainId: string }>();
  const network = getChainById(chainId || "");

  const { data: proposals, status: proposalsStatus } = useProposalsQuery({ network });
  const { data: poolData, status: poolDataStatus } = useStakingPoolQuery({ network });

  const isLoading = proposalsStatus === "pending" || poolDataStatus === "pending";

  const filtered = proposals?.proposals.filter((el) => el.status === tab);

  return (
    <AnimateRoute className="flex flex-col p-0 pb-6">
      <div
        className="ml-8 flex items-center gap-6 text-4xl font-light text-white"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Governance
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      <Tabs.Root
        className="px-48"
        onValueChange={setTab}
        tabs={
          <Tabs.List>
            <Tabs.Trigger value="PROPOSAL_STATUS_VOTING_PERIOD">Voting</Tabs.Trigger>
            <Tabs.Trigger value="PROPOSAL_STATUS_PASSED">Passed</Tabs.Trigger>
            <Tabs.Trigger value="PROPOSAL_STATUS_REJECTED">Rejected</Tabs.Trigger>
          </Tabs.List>
        }
        value={tab}
      >
        <Card className="mt-4">
          {isLoading && (
            <div className="flex justify-center py-5">
              <Spinner className="size-8" />
            </div>
          )}
          {!isLoading && proposalsStatus === "success" && (
            <div className="flex flex-col gap-2">
              {filtered?.map((proposal) => {
                return <ProposalItem key={proposal.id} poolData={poolData} proposal={proposal} />;
              })}
            </div>
          )}

          {proposalsStatus === "success" && filtered?.length === 0 && (
            <div className="py-5 pb-3 text-center text-lg font-medium text-corduroy-600">
              No Data
            </div>
          )}
          {tab === "PROPOSAL_STATUS_VOTING_PERIOD" &&
            !filtered?.length &&
            proposals?.proposals.some((el) => el.status === "PROPOSAL_STATUS_PASSED") && (
              <div className="flex justify-center px-5 pb-5">
                <Button className=" min-w-32" onClick={() => setTab("PROPOSAL_STATUS_PASSED")}>
                  See passed
                </Button>
              </div>
            )}
        </Card>
      </Tabs.Root>
    </AnimateRoute>
  );
};
