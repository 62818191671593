import { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAccount, useDisconnect } from "graz";

import { Button } from "shared/ui/Button";
import { Icon } from "shared/ui/Icon";
import { Modal } from "shared/ui/Modal";

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const WalletModal = ({ isOpen, onOpenChange }: Props) => {
  const { data: account } = useAccount();
  const { disconnectAsync, isLoading } = useDisconnect();

  const { chainId } = useParams<{ chainId: string }>();

  useEffect(() => {
    if (!account?.bech32Address) {
      onOpenChange(false);
    }
  }, [account?.bech32Address, onOpenChange]);

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-[400px]">
        <Modal.Title>Your Wallet</Modal.Title>

        {account && (
          <>
            <div className="mb-5 flex items-center gap-4 rounded-lg bg-tusk-100/10 p-4 text-tusk-100">
              <span className="truncate">{account?.bech32Address}</span>
              <CopyToClipboard
                onCopy={() => toast.success("Copied")}
                text={account?.bech32Address || ""}
              >
                <Icon
                  className="cursor-pointer text-tusk-100 transition-colors hover:text-tusk-300"
                  name="copy"
                />
              </CopyToClipboard>
            </div>
            <div className="mb-5">
              <Link
                className="flex cursor-pointer items-center text-tusk-100 duration-200 hover:text-tusk-300"
                onClick={() => onOpenChange(false)}
                to={`/${chainId}/address/${account.bech32Address}`}
              >
                <span>Go to account page</span>
                <Icon className="size-6 -rotate-90" name="arrowDownSm" />
              </Link>
            </div>
          </>
        )}

        <div className="flex justify-end">
          <Button
            color="secondary"
            isLoading={isLoading}
            onClick={async () => {
              await disconnectAsync();
              onOpenChange(false);
            }}
          >
            Disconnect
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
