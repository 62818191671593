import type { ChainInfo } from "@keplr-wallet/types";

import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { DateTime } from "luxon";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { useLastTransactionInfiniteQuery } from "shared/api/transactions/useLastTransaction";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { timeAgo } from "shared/helpers/timeAgo";
import { Button } from "shared/ui/Button";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";

type Props = {
  network: ChainInfo;
};

export const TransactionListContent = ({ network }: Props) => {
  const navigate = useNavigate();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useLastTransactionInfiniteQuery({
      limit: 20,
    });

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const lastTransactions = useMemo(() => (data ? data.pages.flat() : null), [data]);

  useEffect(() => {
    if (!hasNextPage) return;

    const node = loadMoreRef.current;
    if (!node) return;

    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        fetchNextPage();
      }
    });

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [fetchNextPage, hasNextPage]);

  const columns: TableColumn<string, TransactionQueryResponse>[] = useMemo(
    () => [
      {
        key: "height",
        renderTd: (row) => {
          return (
            <div
              className="flex cursor-pointer items-center gap-3 text-white"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              <Icon
                className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300"
                name="arrowLeftRight"
              />

              <div className="flex flex-col font-light">
                {row.tx_response.height}
                <span className="text-sm text-clay-500">
                  {row.tx_response?.timestamp
                    ? `${timeAgo(DateTime.fromISO(row.tx_response.timestamp))}`
                    : "-"}
                </span>
              </div>
            </div>
          );
        },
        title: "Block",
      },
      {
        key: "hash",
        renderTd: (row) => {
          return (
            <div
              className="col-span-3 cursor-pointer truncate text-clay-300 transition-colors hover:text-white"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              {row.txhash}
            </div>
          );
        },
        title: "Tx Hash",
      },
      {
        key: "message",
        renderTd: (row) => {
          return (
            <div
              className="max-w-48 cursor-pointer truncate"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              {getTxMessage(row.tx.body.messages as TxResponse["tx"]["body"]["messages"])}
            </div>
          );
        },
        title: "Type",
      },
      {
        key: "fees",
        renderTd: (row) => {
          const amount = row.tx.auth_info.fee?.amount?.[0];
          if (!amount) return null;
          return (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              {getFormattedNumber(amount.amount)} {amount.denom}
            </div>
          );
        },
        title: "Fee",
      },
    ],
    [navigate, network.chainId],
  );

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center py-8">
        <Spinner className="size-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 w-full overflow-x-auto">
        <Table columns={columns} data={lastTransactions || []} />
      </div>

      <div className="flex items-center justify-center">
        <Button
          className="mx-auto"
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
          size="medium"
        >
          Next
        </Button>
      </div>
    </div>
  );
};
