import { Outlet } from "react-router-dom";

import { Header } from "../Header";

export const Layout = () => {
  return (
    <div className="h-screen">
      <Header />
      <div className="h-full pt-[var(--header-height)]">
        <Outlet />
      </div>
    </div>
  );
};
