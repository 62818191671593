import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { fromBase64 } from "@cosmjs/encoding";
import { decodeTxRaw } from "@cosmjs/proto-signing";
import { DateTime } from "luxon";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { hashTx } from "shared/helpers/decode";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { EllipsisText } from "shared/ui/EllipsisText";
import { Icon } from "shared/ui/Icon";
import { Table } from "shared/ui/Table";

type Props = {
  transactionsCount?: number;
  txs: { time: string; tx: TransactionQueryResponse | string }[];
};

type TransactionColumn = {
  fee: string;
  hash: string;
  message: string;
  time: string;
};

export const DashboardTableTransactions = ({ transactionsCount, txs }: Props) => {
  const navigate = useNavigate();

  const { chainId } = useParams();

  const chain = getChainById(chainId);

  const sm = useMinWidthMediaQuery("sm");

  const transactions = useMemo(() => {
    const transactions = transactionsCount ? txs.slice(0, transactionsCount) : txs;
    return transactions.map(({ time, tx }): TransactionColumn => {
      const decoded = typeof tx === "string" ? decodeTxRaw(fromBase64(tx)) : null;
      const body = typeof tx !== "string" ? tx.tx.body : null;

      const hash = typeof tx === "string" ? hashTx(fromBase64(tx)) : tx.txhash;

      const fee = !decoded ? null : decoded?.authInfo.fee;
      const amount = !fee ? null : fee.amount?.[0];

      return {
        fee: amount ? getFormattedNumber(amount.amount) + " " + amount.denom : "-",
        hash: hash,
        message: getTxMessage(
          (decoded?.body.messages || body?.messages) as TxResponse["tx"]["body"]["messages"],
        ),
        time,
      };
    });
  }, [txs, transactionsCount]);

  const transactionsColumns = useMemo(() => {
    const columns: TableColumn<keyof TransactionColumn, TransactionColumn>[] = [
      {
        key: "message",
        renderTd: (row) => {
          if (!sm) {
            return (
              <div className="flex flex-col">
                <div className="flex items-center justify-between pb-2">
                  <div
                    className="flex cursor-pointer items-center gap-3 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                    onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
                  >
                    {sm && (
                      <Icon
                        className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300"
                        name="arrowLeftRight"
                      />
                    )}
                    <div className="flex flex-col">
                      {row.message}
                      <span className="text-sm text-clay-500">
                        {timeAgo(DateTime.fromISO(row.time))}
                      </span>
                    </div>
                  </div>

                  <div
                    className="cursor-pointer"
                    onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
                  >
                    <div className="flex w-fit items-center text-sm text-white">
                      <Icon className="mr-2 size-8 p-2 text-tusk-100" name="logo" />
                      {row.fee}
                    </div>
                  </div>
                </div>

                <div
                  className="flex min-w-full cursor-pointer truncate text-sm text-clay-500"
                  onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
                >
                  <span className="mr-3 text-white">Hash</span>
                  <EllipsisText text={row.hash} />
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="flex w-44 cursor-pointer items-center gap-3 border-r border-clay-850 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
              >
                {sm && (
                  <Icon
                    className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300"
                    name="arrowLeftRight"
                  />
                )}
                <div className="flex flex-col">
                  {row.message}
                  <span className="text-sm text-clay-500">
                    {timeAgo(DateTime.fromISO(row.time))}
                  </span>
                </div>
              </div>
            );
          }
        },
        tdClassName: sm ? "w-48" : "",
      },
      {
        key: "hash",
        renderTd: (row) => (
          <div
            className="ml-2 flex cursor-pointer truncate text-sm text-clay-500"
            onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
          >
            <span className="mr-3 text-white">Hash</span>
            <EllipsisText text={row.hash} />
          </div>
        ),
      },
      {
        key: "fee",
        renderTd: (row) => {
          return (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
            >
              <div className="flex items-center text-sm text-white">
                <Icon className="mr-2 size-8 p-2 text-tusk-100" name="logo" />
                {row.fee}
              </div>
            </div>
          );
        },
        tdClassName: "w-36",
      },
    ];

    return sm ? columns : columns.filter((col) => col.key === "message");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain]);

  return (
    <>
      <div className="w-full overflow-x-auto">
        <Table
          className="w-full table-fixed"
          columns={transactionsColumns}
          data={transactions || []}
          hovered
        />
      </div>
    </>
  );
};
