import { useNavigate, useParams } from "react-router-dom";

import { useSupplyByDenomQuery } from "shared/api/bank/useSupplyByDenomQuery";
import { fromUnesToNes } from "shared/helpers/fromUnesToNes";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";

export const Supply = () => {
  const navigate = useNavigate();

  const { chainId } = useParams<{ chainId: string }>();

  const network = getChainById(chainId || "");

  const { data: supply, isLoading: isSupplyLoading } = useSupplyByDenomQuery({ network });

  return (
    <AnimateRoute className="flex flex-col p-0 pb-6">
      <div
        className="ml-8 flex items-center gap-6 text-4xl font-light text-white"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Supply
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      <div className="flex w-full items-center justify-center px-10">
        <div className="flex w-fit flex-col gap-4 rounded-lg bg-clay-900 p-5">
          <div className="flex items-center gap-3 text-base/none text-white">
            <Icon className="size-6 text-tusk-100" name="logo"></Icon>NES
          </div>

          <div className="text-3xl text-clay-300">
            {isSupplyLoading ? (
              <Spinner className="size-3" />
            ) : supply?.amount?.amount ? (
              getFormattedNumber(fromUnesToNes(supply.amount.amount).toNumber())
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </AnimateRoute>
  );
};
