import type { ChainInfo } from "@keplr-wallet/types";

import { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAccount } from "graz";
import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import type { IconName } from "shared/ui/Icon";

import logoSrc from "app/assets/images/logo-name.svg";
import { WalletModal } from "features/WalletModal/WalletModal";
import { WalletProviderModal } from "features/WalletProviderModal";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { useTotalTransactionByPeriodQuery } from "shared/api/transactions/useTotalTransactionByPeriodQuery";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getFormattedNumberWithUnit } from "shared/helpers/getFormattedNumberWithUnit";
import { getShortenedAddress } from "shared/helpers/getShortenedAddress";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Icon } from "shared/ui/Icon";

import { Search } from "./ui/Search";

type MenuItem = {
  icon?: IconName;
  label: string;
  link: string;
};

type DataItem = {
  data: string;
  icon: IconName;
  label: string;
};

type LinkItem = {
  label: string;
  link: string;
};

const getMenuItemsNesa = (chain: ChainInfo): MenuItem[] => [
  { label: "Dashboard", link: `/${chain.chainId}/dashboard` },
  { label: "Transactions", link: `/${chain.chainId}/transactions` },
  { label: "Blocks", link: `/${chain.chainId}/blocks` },
  { label: "Governance", link: `/${chain.chainId}/governance` },
  { label: "Uptime", link: `/${chain.chainId}/uptime` },
  { label: "Supply", link: `/${chain.chainId}/supply` },
];

const linkItems: LinkItem[] = [
  { label: "Visit Nesa.ai", link: "https://nesa.ai/" },
  { label: "Nesa Playground", link: "https://beta.nesa.ai/" },
];

export const Header = () => {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const { data: account, isConnected } = useAccount();

  const chain = getChainById(chainId);
  const menuItems = getMenuItemsNesa(chain);

  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const blocks = useBlocksStore((state) => state.blocksHistory);
  const latestBlock = blocks[0]?.latestBlock?.toString() || "";

  const now = DateTime.now();

  const { data: txChart, isPending: isLoadingTxChart } = useTotalTransactionByPeriodQuery({
    endDate: now.endOf("day").toISO(),
    startDate: now.startOf("day").toISO(),
  });

  const volume = !isLoadingTxChart && txChart ? Object.values(txChart)[0]?.volume : 0;

  const dataItems: DataItem[] = [
    {
      data: `$${getFormattedNumberWithUnit(0.85 * volume)}` || "$---.--M",
      icon: "chartNoAxesColumnIncreasing",
      label: "24h Volume",
    },
    { data: getFormattedNumber(latestBlock), icon: "box", label: "Block" },
  ];

  const sm = useMinWidthMediaQuery("sm");

  return (
    <div className="fixed inset-x-0 top-0 z-20 flex flex-col justify-between gap-2 bg-black pb-2">
      <div className="flex h-11 w-full flex-row gap-1 px-6 sm:items-center sm:pl-8 sm:pr-0">
        {sm && (
          <div className="flex gap-4">
            {linkItems.map((item) => (
              <Link
                className="flex items-center gap-2"
                key={item.label}
                rel="noreferrer"
                target="_blank"
                to={item.link}
              >
                <div className="text-xs text-clay-380 sm:text-sm">{item.label}</div>
                <Icon className="text-white" name="arrowUpRight" />
              </Link>
            ))}
          </div>
        )}

        <div className="flex h-full grow items-center justify-between gap-6 sm:justify-end sm:pr-2">
          <div className="flex gap-6">
            {dataItems.map((item, idx) => {
              if (!sm && idx > 0) {
                return null;
              }

              return (
                <div className="flex items-center gap-2 text-clay-380" key={item.label}>
                  <Icon name={item.icon} />
                  <div>{item.label}</div>
                  <div className="rounded-md bg-clay-900 px-2 text-sm/6 text-white">
                    {item.data}
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="flex h-full cursor-pointer items-center gap-2 rounded-lg border-clay-900 bg-clay-900 px-4 text-xs text-white sm:rounded-none sm:border-l sm:bg-transparent sm:text-sm"
            onClick={() => {
              if (isConnected) {
                setIsWalletOpen(true);
              } else {
                setIsProviderOpen(true);
              }
            }}
          >
            <Icon className="text-tusk-100" name="wallet" />
            {isConnected ? (
              <>{account && getShortenedAddress(account.bech32Address)}</>
            ) : (
              <div>{sm && "Connect"} Wallet</div>
            )}
          </div>

          <WalletProviderModal isOpen={isProviderOpen} onOpenChange={setIsProviderOpen} />
          <WalletModal isOpen={isWalletOpen} onOpenChange={setIsWalletOpen} />
        </div>
      </div>

      <div className="w-full border-b border-clay-900 sm:-mt-2"></div>

      <div className="flex flex-row items-center justify-between pl-6 pr-4 sm:px-8">
        <div className="flex flex-col justify-start gap-2 sm:flex-row sm:gap-0">
          <img
            alt="nesa"
            className="ml-2 w-20 cursor-pointer sm:ml-0"
            onClick={() => navigate("/")}
            src={logoSrc}
          />

          {sm && <MenuItems items={menuItems} />}
        </div>

        <Search />
      </div>
    </div>
  );
};

type MenuItemsProps = {
  disabled?: boolean;
  items: MenuItem[];
};

function MenuItems({ disabled, items }: MenuItemsProps) {
  return (
    <div className="ml-2 flex gap-2 sm:ml-8 sm:gap-6 sm:self-center">
      {items.map((item) => {
        if (!disabled) {
          return (
            <NavLink
              className={({ isActive }) =>
                twMerge(
                  "flex cursor-pointer gap-2 text-xs text-clay-100 transition-all hover:text-clay-20 sm:text-sm",
                  isActive && "text-tusk-100",
                )
              }
              key={item.link}
              to={item.link}
            >
              {item.icon && <Icon name={item.icon} />}
              {item.label}
            </NavLink>
          );
        } else {
          return (
            <span
              className="flex cursor-pointer gap-2 text-xs text-clay-100 transition-all hover:text-clay-20 sm:text-sm"
              key={item.link}
              onClick={() => toast.info("Coming soon")}
            >
              {item.icon && <Icon name={item.icon} />}
              {item.label}
            </span>
          );
        }
      })}
    </div>
  );
}
