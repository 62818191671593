import { Link, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { Proposal } from "shared/api/proposals";
import type { StakingPoolResponse } from "shared/api/staking/types";
import type { TxResponse } from "shared/api/transactions/types";

import { getChainById } from "shared/helpers/getChainById";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { ProgressLine } from "shared/ui/ProgressLine";

import { statusMap } from "../config";

type Props = {
  poolData?: StakingPoolResponse;
  proposal: Proposal;
};

export const ProposalItem = ({ poolData, proposal }: Props) => {
  const md = useMinWidthMediaQuery("md");

  const { chainId } = useParams<{ chainId: string }>();
  const network = getChainById(chainId || "");

  const percent = calculatePercent(
    proposal.final_tally_result.yes_count,
    poolData?.pool.bonded_tokens,
  );

  const statusElement = (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-base font-light text-green-600">{percent}%</div>

        <div className="text-xs font-light text-white">
          {statusMap[proposal.status] || proposal.status}
          <span className="ml-2 text-clay-300">
            {timeAgo(DateTime.fromISO(proposal.voting_end_time))}
          </span>
        </div>
      </div>

      <ProgressLine className="mt-2.5 w-72" classNameLine="bg-green-600" percent={percent ?? 0} />
    </div>
  );

  const idElement = (
    <div className="text-base font-light text-white sm:mr-8 sm:text-2xl">#{proposal.id}</div>
  );

  const messageElement = (
    <div className="inline-block rounded-lg bg-green-100 px-2.5 py-3 text-sm/none text-green-600">
      {getTxMessage(proposal.messages as TxResponse["tx"]["body"]["messages"])}
    </div>
  );

  const titleElement = (
    <div className="flex-1">
      <Link
        className="mb-1.5 block cursor-pointer text-sm font-light text-white duration-200 hover:text-clay-100"
        to={`/${network.chainId}/governance/${proposal.id}`}
      >
        {proposal.title}
      </Link>
      {md && messageElement}
    </div>
  );

  return (
    <div
      className="relative flex flex-col rounded-lg bg-clay-900 px-3 py-4 sm:flex-row sm:items-center sm:px-6"
      key={proposal.id}
    >
      {md && (
        <>
          {idElement}
          {titleElement}
          {statusElement}
        </>
      )}
      {!md && (
        <>
          <div className="flex items-center justify-between gap-3">
            {titleElement}
            {idElement}
          </div>
          <div className="my-2 flex items-center justify-between">
            {messageElement}
            {statusElement}
          </div>
        </>
      )}
    </div>
  );
};

function calculatePercent(input?: number | string, total?: number | string) {
  if (!input || !total) return 0;
  const percent = Number(input) / Number(total);

  return Math.ceil((percent > 0.0001 ? percent : 0) * 100);
}
