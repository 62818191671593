import type { FormEventHandler } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getChainById } from "shared/helpers/getChainById";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Icon } from "shared/ui/Icon";
import { Input } from "shared/ui/Input";
import { Select } from "shared/ui/Select";

import { TransactionListContent } from "./ui/TransactionListContent";

const txhashRegex = /^[A-Z\d]{64}$/;

export const TransactionList = () => {
  const navigate = useNavigate();
  const { chainId } = useParams<{ chainId: string }>();

  const network = getChainById(chainId || "");

  const [hashToSearch, setHashToSearch] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (txhashRegex.test(hashToSearch.trim())) {
      navigate(`${hashToSearch.trim()}`);
      return;
    }

    toast.error("Tx hash is not valid");
  };

  return (
    <AnimateRoute className="flex h-full flex-col p-0 pb-6">
      <div
        className="ml-8 flex items-center gap-6 text-4xl font-light text-white"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Transactions
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      <div className="flex h-8 w-full items-start justify-between px-52 2xl:px-64">
        <form
          className="w-full max-w-full select-none text-center text-2xl/none text-primary-900 md:max-w-[40%] md:text-4xl"
          onSubmit={handleSubmit}
        >
          <Input
            className="w-full"
            classNameInput="text-white"
            classNameInputWrapper="bg-clay-900 border-0"
            onChange={(e) => setHashToSearch(e.target.value)}
            placeholder="Transaction by Tx Hash"
            startSlot={<Icon className="text-clay-100" name="search" />}
            value={hashToSearch}
          />
        </form>

        <div className="flex gap-2">
          <Select className="bg-clay-900 inner-border-0" disabled placeholder="Sort by">
            <Select.Content className="bg-clay-900 inner-border-0">
              <Select.Item className="text-clay-100 hover:bg-clay-800" key="all" value="all">
                All
              </Select.Item>
            </Select.Content>
          </Select>

          <Select className="bg-clay-900 inner-border-0" disabled placeholder="Filter">
            <Select.Content className="bg-clay-900 inner-border-0">
              <Select.Item className="text-clay-100 hover:bg-clay-800" key="all" value="all">
                All
              </Select.Item>
            </Select.Content>
          </Select>
        </div>
      </div>

      <div className="mt-6 h-full overflow-scroll px-52 2xl:px-64">
        <TransactionListContent network={network} />
      </div>
    </AnimateRoute>
  );
};
