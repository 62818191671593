import type { ComponentProps, ElementType, ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";

import { twMerge } from "tailwind-merge";

import type { ClassName, PolymorphicProps } from "shared/types";

import { Spinner } from "../Spinner";

export type ButtonVariant = "filled" | "filled-light";
export type ButtonColor = "primary" | "secondary";

export type Props = {
  asIcon?: boolean;
  color?: ButtonColor;
  isLoading?: boolean;
  size?: "extra-small" | "medium" | "small";
  variant?: ButtonVariant;
} & ClassName;

const Button = <E extends ElementType = "button">(
  props: PropsWithChildren<PolymorphicProps<E, Props>>,
  ref: ForwardedRef<E>,
) => {
  const {
    as: Component = "button",
    asIcon,
    children,
    className,
    color = "primary",
    disabled,
    isLoading,
    size = "small",
    variant = "filled",
    ...restProps
  } = props;

  const isLocked = disabled || isLoading;

  const isSecondaryLightFilled = color === "secondary" && variant === "filled-light";
  const isPrimaryLightFilled = color === "primary" && variant === "filled-light";
  const isPrimaryFilled = color === "primary" && variant === "filled";
  const isSecondaryFilled = color === "secondary" && variant === "filled";

  const iconButtonSize = {
    "extra-small": "size-8",
    medium: "size-[2.875rem]",
    small: "size-10",
  }[size];
  const buttonSize = {
    "extra-small": "text-xs h-8 px-3 py-1",
    medium: "text-sm h-[2.875rem] px-5 py-4",
    small: "text-xs h-10 px-3.5 py-2 3xl:text-sm",
  }[size];

  return (
    <Component
      className={twMerge(
        "relative inline-flex cursor-pointer items-center justify-center gap-1 rounded-lg border border-transparent text-center font-medium transition-all",
        isLocked && "pointer-events-none cursor-not-allowed",
        isLocked && "opacity-60",

        asIcon ? iconButtonSize : buttonSize,

        isSecondaryLightFilled &&
          "border-corduroy-100 bg-corduroy-100 text-corduroy-900 hover:brightness-95",
        isPrimaryFilled && "border-tusk-100 bg-tusk-100 hover:border-tusk-300 hover:bg-tusk-300",
        isPrimaryLightFilled && "border-clay-100 bg-clay-100 text-tusk-300 hover:brightness-95",
        isSecondaryFilled &&
          "border-steel-900 bg-steel-900 text-white hover:border-steel-800 hover:bg-steel-800",

        // isBorderPrimary && 'border-gray-300 hover:border-primary-500',
        // isBorderSecondary && 'border-gray-300 hover:border-corduroy-600',

        // isPrimaryText && 'text-primary-900 hover:text-primary-1000',
        // isSecondaryText && 'text-corduroy-900 hover:text-corduroy-800',
        className,
      )}
      disabled={disabled || isLoading}
      ref={ref as ComponentProps<typeof Component>["ref"]}
      {...restProps}
    >
      {isLoading ? <Spinner className="size-4" /> : children}
    </Component>
  );
};

const ForwardButton = forwardRef(Button) as typeof Button;

export { ForwardButton as Button };
