import type { Coin } from "@keplr-wallet/types";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import { useTransactionByHashQuery } from "shared/api/transactions/useTransactionByHashQuery";
import { base64ToString } from "shared/helpers/base64ToString";
import { timeAgo } from "shared/helpers/timeAgo";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Card } from "shared/ui/Card";
import { HexDecodeText } from "shared/ui/HexDecodeText";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Tabs } from "shared/ui/Tabs";

const rowStyles = "grid grid-cols-6 border-b border-b-clay-900 py-3 text-clay-300";

const getSectionTitleByKey = (key: string) => {
  if (key === "txhash") {
    return "Tx Hash";
  }

  return key.replace(/[@_]/, " ").trim();
};

const checkIsAmountArray = (value: unknown): value is Coin[] => {
  const hasAmount = !!(value as Coin[])?.[0]?.amount;

  return hasAmount;
};

const checkIsAmount = (value: unknown): value is Coin => {
  const hasAmount = !!(value as Coin)?.amount;

  return hasAmount;
};

export const Transaction = () => {
  const navigate = useNavigate();
  const { chainId, hash } = useParams<{ chainId: string; hash: string }>();
  const { data, isPending } = useTransactionByHashQuery({ hash: hash! }, { enabled: !!hash });

  const { tx, tx_response } = data || {};

  const isSuccessful = tx_response?.code === 0;

  const messages =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tx?.body?.messages.map((msg: any) => {
      if (msg.packet?.data) {
        return {
          ...msg,
          message: base64ToString(msg.packet.data),
        };
      }
      return msg;
    }) || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const feeData = (tx as any)?.["auth_info"]?.fee?.amount[0];

  return (
    <AnimateRoute className="relative flex h-full flex-col p-0 pb-5">
      <div className="mx-8 flex items-center justify-between">
        <div
          className="flex items-center gap-6 text-4xl font-light text-white"
          onClick={() => navigate(`/`)}
        >
          <Icon
            className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
            name="arrowLeft"
          />
          Transaction Details
        </div>

        <div className="flex items-center gap-4 rounded-lg bg-clay-900 px-4 py-3">
          <div className="text-white">Tx Hash</div>
          <div className="col-span-5 text-clay-300">{hash}</div>
          <CopyToClipboard onCopy={() => toast.success("Copied")} text={hash || ""}>
            <Icon className="size-4 cursor-pointer text-clay-300 hover:text-white" name="copy" />
          </CopyToClipboard>
        </div>
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      {isSuccessful ? (
        <div className="h-full overflow-scroll">
          <Card className="mb-6 px-64">
            <Card.Content className="px-44">
              <h1 className="mb-3 text-3xl text-white">Summary</h1>

              {isPending ? (
                <div className="flex flex-col items-center justify-center py-8">
                  <Spinner className="size-6" />
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className={rowStyles}>
                    <div className="text-white">Height</div>
                    <div className="col-span-5 text-clay-300">{tx_response?.height}</div>
                  </div>
                  <div className={twMerge(rowStyles, "py-2")}>
                    <div className="flex items-center text-white">Status</div>
                    <div
                      className={twMerge(
                        "col-span-5 w-fit rounded-md bg-pink-500/20 px-2 py-1 text-pink-500",
                        isSuccessful && "bg-primary-900/20 text-primary-900",
                      )}
                    >
                      {isSuccessful ? "Success" : "Failed"}
                    </div>
                  </div>
                  <div className={rowStyles}>
                    <div className="text-white">Time</div>
                    <div className="col-span-5 text-clay-300">
                      {tx_response?.timestamp
                        ? `${DateTime.fromISO(tx_response.timestamp).toFormat("yyyy-MM-dd hh:mm:ss")} (${timeAgo(DateTime.fromISO(tx_response.timestamp))})`
                        : "-"}
                    </div>
                  </div>

                  <div className={rowStyles}>
                    <div className="text-white">Gas</div>
                    <div className="col-span-5 text-clay-300">
                      {tx_response?.gas_used || "-"} / {tx_response?.gas_wanted || "-"}
                    </div>
                  </div>

                  <div className={rowStyles}>
                    <div className="text-white">Fee</div>
                    <div className="col-span-5 text-clay-300">
                      {feeData?.amount} {feeData?.denom}
                    </div>
                  </div>

                  <div className={twMerge(rowStyles, "border-b-transparent pb-0")}>
                    <div className="text-white">Memo</div>
                    <div className="col-span-5 text-clay-300">{tx?.body.memo || "Not defined"}</div>
                  </div>
                </div>
              )}
            </Card.Content>
          </Card>

          {messages.length > 0 && (
            <Card className="px-64">
              <Card.Content className="border-t px-44">
                <h1 className="mb-3 text-3xl text-white">Messages ({messages.length})</h1>

                {messages.map((msg, i) => {
                  return (
                    <div className="flex flex-col" key={i}>
                      <h2 className="pb-1 pt-4 font-medium text-white">Message {i + 1}</h2>

                      {Object.entries(msg).map(([key, value]) => {
                        const isStr = typeof value === "string";
                        const isAccount = key === "account";
                        const isAmountArray = checkIsAmountArray(value);
                        const isAmount = checkIsAmount(value);
                        const isVrf = key === "vrf";

                        return (
                          <div className={rowStyles} key={key}>
                            <div className="capitalize text-white">{getSectionTitleByKey(key)}</div>
                            {isStr &&
                              (isAccount ? (
                                <div
                                  className="col-span-5 cursor-pointer font-medium text-tusk-100 transition-colors hover:text-tusk-300"
                                  onClick={() => {
                                    navigate(`/${chainId}/address/${value}`);
                                  }}
                                >
                                  {value}
                                </div>
                              ) : (
                                <div className="col-span-5">{value}</div>
                              ))}
                            {isAmountArray && (
                              <div className="col-span-5">
                                {value?.[0].amount}
                                {value?.[0].denom}
                              </div>
                            )}
                            {isAmount && (
                              <div className="col-span-5">
                                {value?.amount}
                                {value?.denom}
                              </div>
                            )}
                            {isVrf && (
                              <div className="col-span-5">
                                <Tabs.Root
                                  defaultValue="seed"
                                  tabs={
                                    <Tabs.List className="mb-4">
                                      <Tabs.Trigger value="seed">Seed</Tabs.Trigger>
                                      <Tabs.Trigger value="proof">Proof</Tabs.Trigger>
                                      <Tabs.Trigger value="hash_random">Hash Random</Tabs.Trigger>
                                    </Tabs.List>
                                  }
                                >
                                  <Tabs.Content className="w-full" value="seed">
                                    <HexDecodeText value={msg.vrf.seed} />
                                  </Tabs.Content>
                                  <Tabs.Content className="w-full truncate" value="proof">
                                    {msg.vrf.proof}
                                  </Tabs.Content>
                                  <Tabs.Content className="w-full" value="hash_random">
                                    <HexDecodeText value={msg.vrf.hash_random} />
                                  </Tabs.Content>
                                </Tabs.Root>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Card.Content>
            </Card>
          )}
        </div>
      ) : (
        <div className="flex w-full justify-center text-3xl font-light text-white">
          Transaction Not Found
        </div>
      )}
    </AnimateRoute>
  );
};
