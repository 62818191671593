import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { fromHex, toBase64 } from "@cosmjs/encoding";
import { DateTime } from "luxon";

import type { ValidatorUnit } from "shared/api/blocks/types";
import type { SigningInfo } from "shared/api/slashing/types";
import type { TableColumn } from "shared/ui/Table";

import { useGetSlashingParamsQuery } from "shared/api/slashing/useGetSlashingParamsQuery";
import { useGetSlashingSigningInfoQuery } from "shared/api/slashing/useGetSlashingSigningInfoQuery";
import { useGetValidatorsQuery } from "shared/api/validators/useGetValidatorsQuery";
import { consensusPubkeyToHexAddress } from "shared/helpers/decode";
import { getChainById } from "shared/helpers/getChainById";
import { timeAgo } from "shared/helpers/timeAgo";
import { valconsToBase64 } from "shared/helpers/valconsToBase64";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";
import { Tabs } from "shared/ui/Tabs";

import { BlocksTab } from "./ui/BlocksTab";

export const Uptime = () => {
  const navigate = useNavigate();

  const { chainId } = useParams<{ chainId: string }>();
  const network = getChainById(chainId || "");

  const { data, isPending } = useGetValidatorsQuery({
    limit: 500,
    network,
    status: "BOND_STATUS_BONDED",
  });
  const { data: slashingSiginingQuery } = useGetSlashingSigningInfoQuery({ limit: 300, network });
  const { data: slashingParam } = useGetSlashingParamsQuery({ network });

  const signedBlocksWindow = slashingParam?.params?.signed_blocks_window;

  const columns: TableColumn<string, ValidatorUnit>[] = [
    {
      key: "moniker",
      renderTd: (row) => (
        <div className="flex items-center gap-2.5 text-sm/none text-white">
          <Icon className="size-6 text-tusk-100" name="logo"></Icon>
          {row.moniker}
        </div>
      ),
      title: "Validator",
    },
    {
      key: "uptime",
      renderTd: (row) => (
        <span className="text-sm text-clay-300">{row.uptime ? `${row.uptime * 100}%` : "-"}</span>
      ),
      title: `Uptime ${signedBlocksWindow ? `(${signedBlocksWindow} Blocks)` : ""}`,
    },
    {
      key: "jailed_until",
      renderTd: (row) => (
        <span className="text-sm text-clay-500">
          {row.signing?.jailed_until
            ? DateTime.fromISO(row.signing.jailed_until).year < 2024
              ? "never"
              : timeAgo(DateTime.fromISO(row.signing?.jailed_until))
            : ""}
        </span>
      ),
      title: "Last Jailed Time",
    },
    {
      key: "precommits",
      renderTd: (row) => <span className="text-sm text-clay-300">{row.signing?.index_offset}</span>,
      title: "Signed Precommits",
    },
    {
      key: "height",
      renderTd: (row) => <span className="text-sm text-clay-300">{row.signing?.start_height}</span>,
      title: "Start Height",
    },
    {
      key: "tombstoned",
      renderTd: (row) => (
        <span className="text-sm text-clay-500">{`${row.signing?.tombstoned}`}</span>
      ),
      title: "Tombstoned",
    },
  ];

  const signingInfoMap = slashingSiginingQuery?.info?.reduce<Record<string, SigningInfo>>(
    (acc, info) => {
      acc[valconsToBase64(info.address)] = info;

      return acc;
    },
    {},
  );

  const validatorsData = useMemo(() => {
    const window = Number(slashingParam?.params?.signed_blocks_window || 0);

    return data?.validators?.map((v) => {
      const hex = consensusPubkeyToHexAddress(v.consensus_pubkey);
      const signing = signingInfoMap?.[hex];
      const uptime =
        signing && window > 0
          ? (window - Number(signing.missed_blocks_counter)) / window
          : undefined;

      return {
        base64: toBase64(fromHex(hex)),
        hex,
        missed_blocks_counter: signing?.missed_blocks_counter,
        moniker: v.description.moniker,
        signing,
        uptime,
      } as ValidatorUnit;
    });
  }, [signingInfoMap, slashingParam?.params?.signed_blocks_window, data?.validators]);

  return (
    <AnimateRoute className="flex flex-col p-0 pb-6">
      <div
        className="ml-8 flex items-center gap-6 text-4xl font-light text-white"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Uptime
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      <div className="px-48">
        {isPending ? (
          <div className="flex justify-center py-4">
            <Spinner className="size-6" />
          </div>
        ) : (
          <div className="flex flex-col">
            <Tabs.Root
              defaultValue="overall"
              tabs={
                <Tabs.List className="mb-4">
                  <Tabs.Trigger value="overall">Overall</Tabs.Trigger>
                  <Tabs.Trigger value="blocks">Blocks</Tabs.Trigger>
                </Tabs.List>
              }
            >
              <Tabs.Content className="w-full" value="overall">
                <Table columns={columns} data={validatorsData || []} />
              </Tabs.Content>
              <Tabs.Content className="w-full" value="blocks">
                <BlocksTab validators={validatorsData || []} />
              </Tabs.Content>
            </Tabs.Root>
          </div>
        )}
      </div>
    </AnimateRoute>
  );
};
