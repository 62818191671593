import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { LatestBlock } from "shared/api/blocks/types";
import type { TableColumn } from "shared/ui/Table";

import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getValidatorCosmosByAddress } from "features/useValidatorsCosmos";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";
import { Icon } from "shared/ui/Icon";
import { Table } from "shared/ui/Table";

type Props = {
  blocksCount?: number;
  withHeader?: boolean;
};

export const TableBlocks = ({ blocksCount, withHeader = true }: Props) => {
  const navigate = useNavigate();

  const { blocksHistory } = useBlocksStore();

  const { chainId } = useParams();
  const chain = getChainById(chainId);

  const columns = useMemo(
    (): TableColumn<string, LatestBlock>[] => [
      {
        key: "latestBlock",
        renderTd: (row) => (
          <div
            className="flex cursor-pointer items-center gap-3 text-sm font-light text-white"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            <Icon className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300" name="box" />
            <div className="flex flex-col">{getFormattedNumber(row.latestBlock)}</div>
          </div>
        ),
        title: withHeader ? "Block" : undefined,
      },
      {
        key: "proposer",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm font-light text-clay-300"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {getValidatorCosmosByAddress(row.proposerAddress)?.moniker || row.proposerAddress}
          </span>
        ),
        title: withHeader ? "Proposer" : undefined,
      },
      {
        key: "time",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm text-corduroy-700"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {timeAgo(DateTime.fromISO(row.time))}
          </span>
        ),
        title: withHeader ? "Time" : undefined,
      },
      {
        key: "hash",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm text-clay-300 hover:text-white"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {row.hash}
          </span>
        ),
        title: withHeader ? "Hash" : undefined,
      },
      {
        key: "txn",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm text-corduroy-700"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {row.transactions.length}
          </span>
        ),
        title: withHeader ? "Txn" : undefined,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chain.chainId, withHeader],
  );

  const blocks = useMemo(() => {
    if (typeof blocksCount === "number") {
      return blocksHistory.slice(0, blocksCount);
    }
    return blocksHistory;
  }, [blocksCount, blocksHistory]);

  return (
    <div className="w-full overflow-x-auto">
      <Table className="min-w-[64rem]" columns={columns} data={blocks} hovered />
    </div>
  );
};
