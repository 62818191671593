import { useNavigate } from "react-router-dom";

import { TableBlocks } from "features/TableBlocks";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Icon } from "shared/ui/Icon";

import { BarChart } from "./ui/BarChart";

export const Blocks = () => {
  const { blocksHistory } = useBlocksStore();
  const navigate = useNavigate();

  return (
    <AnimateRoute className="relative flex h-full flex-col p-0 pb-5">
      <div
        className="ml-8 flex items-center gap-6 text-4xl font-light text-white"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Blocks
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      <div className="size-full overflow-scroll px-52 2xl:px-64">
        <BarChart latestBlocks={blocksHistory} />

        <TableBlocks />
      </div>
    </AnimateRoute>
  );
};
