import { useMemo, useState } from "react";

import { convertHash } from "shared/helpers/decode";
import { Icon } from "shared/ui/Icon";

export const HashValue = ({ hash }: { hash?: string }) => {
  const [showHash, setShowHash] = useState(true);

  const decodeHash = useMemo(() => {
    return convertHash(hash);
  }, [hash]);

  return (
    <div className="flex w-full items-center justify-between text-clay-300">
      <div className="flex-1 truncate">{showHash ? hash : decodeHash}</div>
      {decodeHash && (
        <Icon
          className="size-[1.125rem] cursor-pointer text-tusk-100 transition-all duration-200 hover:text-tusk-300"
          name="rotate"
          onClick={() => setShowHash(!showHash)}
        />
      )}
    </div>
  );
};
