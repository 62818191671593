import { useEffect, useRef, useState } from "react";

import { twMerge } from "tailwind-merge";

interface EllipsisTextProps {
  className?: string;
  text: string;
}

export const EllipsisText = ({ className, text }: EllipsisTextProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);

  const [displayText, setDisplayText] = useState(text);

  const updateEllipsis = () => {
    const container = containerRef.current;
    const measure = measureRef.current;

    if (!container || !measure) return;

    setDisplayText(text);

    requestAnimationFrame(() => {
      const containerWidth = container.clientWidth;
      measure.innerText = text;
      const fullWidth = measure.offsetWidth;

      if (fullWidth <= containerWidth) {
        setDisplayText(text);
        return;
      }

      const totalLen = text.length;
      const afterEllipsis = 5;

      let left = 0;
      let right = totalLen - afterEllipsis;
      let bestFront = 0;

      const measureText = (frontCount: number) => {
        const truncated = text.slice(0, frontCount) + "..." + text.slice(-afterEllipsis);
        measure.innerText = truncated;
        return measure.offsetWidth;
      };

      while (left <= right) {
        const mid = Math.floor((left + right) / 2);

        const w = measureText(mid);
        if (w <= containerWidth) {
          bestFront = mid;
          left = mid + 1;
        } else {
          right = mid - 1;
        }
      }

      setDisplayText(text.slice(0, bestFront) + "..." + text.slice(-afterEllipsis));
    });
  };

  useEffect(() => {
    updateEllipsis();
    const onResize = () => updateEllipsis();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <div className={twMerge("overflow-hidden", className)} ref={containerRef}>
      {displayText}

      <span className="invisible absolute whitespace-nowrap" ref={measureRef} />
    </div>
  );
};
