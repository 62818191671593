import { memo } from "react";

import { twMerge } from "tailwind-merge";

import type { TableColumn, TableProps, TableRowExt } from "../types";

type Props<ColumnKey extends string, Row extends TableRowExt<ColumnKey>> = {
  column: TableColumn<ColumnKey, Row>;
} & Pick<TableProps<ColumnKey, Row>, "onSortChange" | "sort">;

const Th = <ColumnKey extends string, Row extends TableRowExt<ColumnKey>>({
  column,
  onSortChange,
  sort,
}: Props<ColumnKey, Row>) => {
  const onSortButtonClick = () => {
    onSortChange?.({
      key: column.key,
      type: sort?.type && sort.key === column.key ? "desc" : "asc",
    });
  };

  return (
    <th
      className={twMerge(
        "sticky top-0 bg-transparent p-3 text-left text-sm font-normal text-white first:rounded-tl-lg last:rounded-tr-lg",
        column.thClassName,
      )}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="flex-1">{column.title}</div>
        {column.withSort && (
          <button
            className="inline-flex size-8 cursor-pointer items-center justify-center"
            onClick={onSortButtonClick}
          >
            <svg
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.9313 9.00005H16.0686C16.6744 9.00005 16.9773 9.00005 17.1175 8.88025C17.2393 8.7763 17.3038 8.62038 17.2913 8.46082C17.2768 8.27693 17.0626 8.06274 16.6342 7.63436L12.5656 3.56573C12.3676 3.36772 12.2686 3.26872 12.1544 3.23163C12.054 3.199 11.9458 3.199 11.8454 3.23163C11.7313 3.26872 11.6323 3.36772 11.4342 3.56573L7.36561 7.63436C6.93724 8.06273 6.72305 8.27693 6.70858 8.46082C6.69602 8.62038 6.76061 8.7763 6.88231 8.88025C7.02257 9.00005 7.32548 9.00005 7.9313 9.00005Z"
                stroke={sort?.key === column.key && sort.type === "asc" ? "#1A1C20" : "#8E9197"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <path
                d="M16.0686 15H7.9313C7.32548 15 7.02257 15 6.88231 15.1198C6.76061 15.2238 6.69602 15.3797 6.70858 15.5393C6.72305 15.7232 6.93724 15.9374 7.36561 16.3657L11.4342 20.4344C11.6323 20.6324 11.7313 20.7314 11.8454 20.7685C11.9458 20.8011 12.054 20.8011 12.1544 20.7685C12.2686 20.7314 12.3676 20.6324 12.5656 20.4344L16.6342 16.3657C17.0626 15.9374 17.2768 15.7232 17.2913 15.5393C17.3038 15.3797 17.2392 15.2238 17.1175 15.1198C16.9773 15 16.6744 15 16.0686 15Z"
                stroke={sort?.key === column.key && sort.type === "desc" ? "#1A1C20" : "#8E9197"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </button>
        )}
      </div>
    </th>
  );
};

const ThMemo = memo(Th) as typeof Th;

export { ThMemo as Th };
