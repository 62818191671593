import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  classNameLine?: string;
  percent: number;
};

export const ProgressLine = ({ className, classNameLine, percent }: Props) => {
  return (
    <div
      className={twMerge(
        "relative col-span-3 h-1.5 w-full overflow-hidden bg-corduroy-200",
        className,
      )}
    >
      <div
        className={twMerge("h-full bg-green-600", classNameLine)}
        style={{
          width: `${percent}%`,
        }}
      />
    </div>
  );
};
